const TOKEN_KEY = '@TCAuthTrader:access_token';

const TokenStorageService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  saveOnlineToken(token) {
    localStorage.setItem('OnlineToken', token);
  },

  saveOnlineRefresToken(token) {
    localStorage.setItem('OnlineRefreshToken', token);
  },

  getOnlineToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  getOnlineRefreshToken() {
    return localStorage.getItem('OnlineRefreshToken');

  }
};

export default TokenStorageService;
