import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import CustomRoute from './CustomRoute';
import useAuth from '../contexts/Session';
import Loading from '../components/App/Loading';
import { LoadingProvider } from '../contexts/Loading';

const LoggedInComponent = lazy(() => import('../components/Logged_in/Main'));
const LoggedOutComponent = lazy(() => import('../components/Logged_out/Main'));

export default function Routes() {
  const { loading } = useAuth();

  if (loading) {
    return <Loading open />;
  }

  return (
    <LoadingProvider>
      <Switch>
        <PrivateRoute path="/app" component={LoggedInComponent} />
        <CustomRoute path="/login" component={LoggedOutComponent} />
        <Route component={() => <Redirect to="/app" />} />
      </Switch>
    </LoadingProvider>
  );
}
