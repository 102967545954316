const USER_KEY = '@TCAuthTrader:user';

const USER_ID = '@TCAuthTrader:user_id';

const UserStorageService = {
  getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  },

  getUserID() {
    return JSON.parse(localStorage.getItem(USER_ID));
  },
  
  saveUser(user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  },

  saveUserId(user) {
    localStorage.setItem(USER_ID, JSON.stringify(user));
  },

  removeUserId() {
    localStorage.removeItem(USER_ID);
  },

  removeUser() {
    localStorage.removeItem(USER_KEY);
  },

  saveAccess(email, senha) {
    localStorage.setItem("@TCAuthTrader:access", JSON.stringify({
      email: email,
      senha: senha
    }))
  },

  getAccess() {
    return JSON.parse(localStorage.getItem("@TCAuthTrader:access"));
  }

};

export default UserStorageService;
