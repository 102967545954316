import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TokenStorageService, UserStorageService } from '../../services/Storage';

const ChatContext = createContext();
export const ChatProvider = (props) => {
  const { children } = props;
  const [usersListChat, setUsersListChat] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setUserId("5ddf91e2-76d3-46dc-aadd-41d6e33a5463")
  }, [])

  return (
    <ChatContext.Provider
      value={{ setUsersListChat, usersListChat, userId, setUserId }}
    >
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default () => useContext(ChatContext);
