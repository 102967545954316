export default class NotFoundError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message =
      message ||
      'Página/serviço indisponível. Contate o suporte para mais informações.';
    this.errorCode = errorCode || 404;
  }
}
