import ApiService from '../API';
import { TokenStorageService, UserStorageService } from '../Storage';

const AuthService = {
  login(email, password) {
    return ApiService.post('analysts/login', { email, password })
      .then((response) => {
        TokenStorageService.saveToken(response.data.data.token);

        const userData = {
          name: response.data.data.name,
          email: response.data.data.email,
          full_access: response.data.data.full_access
        };

        UserStorageService.saveUser(userData);
        ApiService.setHeader();
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  logout() {
    TokenStorageService.removeToken();
    UserStorageService.removeUser();
    ApiService.removeHeader();
  },
};

export default AuthService;
