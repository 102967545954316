import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import ApiService from '../../services/API';
import { TokenStorageService, UserStorageService } from '../../services/Storage';
import AuthService from '../../services/Session';

const SessionContext = createContext();

export const SessionProvider = (props) => {
  const { children } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState({});
  const [loading, setLoading] = useState(true);

  function getUserAuthenticated() {
    const user = UserStorageService.getUser();
    setUserAuthenticated(user);
  }

  useEffect(() => {
    try {
      const token = TokenStorageService.getToken();
      if (token) {
        const { exp } = jwtDecode(token);
        if (exp > new Date().getTime() / 1000) {
          ApiService.setHeader();
          getUserAuthenticated();
          setIsAuthenticated(true);
        } else {
          signOut();
        }
      }
    } catch (error) {
      enqueueSnackbar(
        'Token de acesso inválido. Favor realizar o login novamente.',
        {
          variant: 'error',
        },
      );
    }
    setLoading(false);
  }, []);

  function signIn(email, password) {
    return AuthService.login(email, password).then((response) => {
      getUserAuthenticated();
      setIsAuthenticated(true);
      return response;
    });
  }

  function signOut() {
    setIsAuthenticated(false);
    setUserAuthenticated({});
    AuthService.logout();
  }

  return (
    <SessionContext.Provider
      value={{ loading, isAuthenticated, userAuthenticated, signIn, signOut }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default () => useContext(SessionContext);
